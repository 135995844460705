<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <table
      class="table_text_code"
      :style="style"
    >
      <tbody>
        <tr>
          <td
            v-for="base in bases"
            :key="`label_${base}`"
            v-dompurify-html="label(base)"
          />
        </tr>
        <tr>
          <td
            v-for="(base, index) in bases"
            :key="`input_${base}`"
          >
            <input
              :class="`${valid(base, index)}${classes}`"
              :disabled="disabled"
              :maxlength="maxLength(base)"
              :size="maxLength(base)"
              :data-text-code="index"
              :value="inputValue(index)"
              @change="onChange"
              @paste="onPaste"
              @keypress="onKeyPress"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeTextCode',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      pasted: false
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let value = builder.getCellValue(index, this.cell, this.clone, {})
      try {
        if (typeof value === 'string') {
          value = JSON.parse(value)
        }
      } catch (ex) {
        // Keine Aktion nötig
      }
      return value
    },
    bases() {
      const bases = []
      const prefix = 'text_code_input_'
      const settings = this.cell.settings
      for (let i = 0, base; (base = prefix + i); i++) {
        if (settings[base]) {
          bases.push(base)
        } else {
          break
        }
      }
      return bases
    }
  },
  methods: {
    label(base) {
      const builder = this.data.ChecklistBuilder
      const settings = this.cell.settings
      return builder.getLocalization(settings, `${base}_label`) || ''
    },
    inputValue(index) {
      return (this.value[index] || {}).value || ''
    },
    maxLength(base) {
      const settings = this.cell.settings
      return settings[`${base}_length`] || false
    },
    optional(base) {
      const settings = this.cell.settings
      return settings[`${base}_optional`] || false
    },
    valid(base, index) {
      if (this.optional(base, index)) {
        return ''
      }
      if ((this.value[index] || {}).passed === false) {
        return 'code-error'
      }
      return ''
    },
    getTextCodeValue(index, data, value) {
      value = value || ''
      const base = `text_code_input_`
      const dataEditor = this.cell
      const settings = dataEditor.settings || {}
      const result = {
        passed: true,
        result: ''
      }
      const evaluateCode = function (regex, inputValue) {
        if (regex.test(inputValue.value)) {
          inputValue.passed = true
        } else {
          inputValue.passed = false
          result.passed = false
        }
      }
      for (let i = 0, prefix; (prefix = base + i); i++) {
        if (!settings[prefix]) {
          break
        }
        const codeLength = settings[prefix + '_length']
        const codeType = settings[prefix + '_type'] || '0'
        const codeOptional = settings[prefix + '_optional']
        let inputValue
        if (index === i) {
          if (value.length > codeLength) {
            inputValue = {
              value: value.substring(0, codeLength),
              passed: true
            }
            value = value.substring(codeLength)
            index++
          } else {
            inputValue = {
              value: value,
              passed: true
            }
          }
        } else {
          inputValue = {
            value: (data[i] || {}).value || '',
            passed: true
          }
        }
        let regexSuffix = '*'
        if (codeLength && !codeOptional) {
          regexSuffix = `{${codeLength}}`
        }

        if (!codeOptional) {
          result.result += inputValue.value
        }

        switch (codeType) {
          case '0': // 'Text (a-Z, 0-9)'
            evaluateCode(new RegExp(`^[a-z0-9]${regexSuffix}$`, 'i'), inputValue)
            break
          case '1': // 'Number (0-9)'
            evaluateCode(new RegExp(`^[0-9]${regexSuffix}$`, 'i'), inputValue)
            break
          case '2': // 'Hexadecimal (a-F, 0-9)'
            evaluateCode(new RegExp(`^[a-f0-9]${regexSuffix}$`, 'i'), inputValue)
            break
          case '3': // 'Binary (0-1)'
            evaluateCode(new RegExp(`^[0-1]${regexSuffix}$`, 'i'), inputValue)
            break
        }
        result[i] = inputValue
      }
      return result
    },
    onChange(event) {
      if (this.pasted) {
        this.pasted = false
        return
      }
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const codeIndex = parseInt(event.target.getAttribute('data-text-code')) || 0
      const previousValue = builder.getCellValue(index, this.cell, this.clone, {})
      const value = this.getTextCodeValue(codeIndex, previousValue, event.target.value)
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    },
    onPaste(event) {
      this.pasted = true
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const codeIndex = parseInt(event.target.getAttribute('data-text-code')) || 0
      const previousValue = builder.getCellValue(index, this.cell, this.clone, {})
      const text = event.clipboardData.getData('text')
      const value = this.getTextCodeValue(codeIndex, previousValue, text)
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
      event.target.blur()
    },
    onKeyPress(event) {
      const keyCode = ~~(event.keyCode || event.which)
      switch (keyCode) {
        case 13: case 8:
          return
        default: {
          const codeIndex = parseInt(event.target.getAttribute('data-text-code')) || 0
          const settings = this.cell.settings || {}
          const codeLength = settings[`text_code_input_${codeIndex}_length`]
          const value = event.target.value || ''
          if (codeLength && value.length >= codeLength) {
            const selector = `input[data-text-code="${codeIndex + 1}"]`
            const nextInput = event.target.closest('tr').querySelector(selector)
            if (nextInput) {
              nextInput.focus()
              nextInput.value = String.fromCharCode(keyCode)
            }
          }
        }
          break
      }
    }
  }
}
</script>

<style>
  .checklist-viewer .checklist-content .element-content .table_text_code {
    width: auto;
    margin: 0 auto;
    border-spacing: 4px
  }

  .checklist-viewer .checklist-content .element-content .table_text_code
  .code-error {
    border: 1px solid #ff0000;
    padding: 2px;
  }
</style>
